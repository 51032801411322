import CourierAvailableHoursService from "@/services/CourierAvailableHoursService";
export const namespaced = true;

const getDefaultState = () => {
    return {
        availability: [],
        days: [],
        courier: null,
        loading: false,
        error: {}
    }
}
export const state = getDefaultState();

export const mutations = {
    SET_AVAILABILITY(state, availability) {
        state.availability = availability;
    },
    SET_DAYS(state, days) {
        state.days = days;
    },
    SET_COURIER(state, courier) {
        state.courier = courier
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    }

}

export const actions = {
    getCourierAvailability({commit}, payload)
    {

        commit('SET_COURIER', CourierAvailableHoursService.translateOption(payload.courier.courier))
        commit('SET_LOADING', true);
        CourierAvailableHoursService.index({
            courier: state.courier,
            postalCode: payload.courier.postalCode
        })
            .then((response) => {
                let days = [];
                response.data.forEach(function (item){
                    days.push(item.date);
                })

                commit('SET_AVAILABILITY',response.data);
                commit('SET_DAYS',days);
                commit('SET_LOADING', false);
            })
            .catch((errors) => {
                commit('SET_ERROR',errors)
            });
    },
    resetState({commit}) {
        commit('RESET_STATE');
    },
}

export const getters = {
    availability: (state) => {
        return state.availability;
    },
    days: (state) => {
        return state.days;
    },
    courier: (state) => {
        return state.courier;
    },
    loading: (state) => {
        return state.loading;
    },
    error: (state) => {
        return state.error;
    },
};