<template>

  <div id="eco-transport" style="min-height: 425px;">
    <v-container fluid :class="classes.buttons_container">
      <v-row dense no-gutters>
        <v-col>
          <app-animated-button variant="transport_dpd"
                               :selected="claim_form.transport_in === 'transport_dpd'"
                               @click="updateClaimForm({ key: 'transport_in', value: 'transport_dpd' });
                                       loadCourierData('transport_dpd');
                                       updateClaimForm({ key: 'transport_out', value: 'transport_courier' }); "/>
          <v-btn icon="mdi-help" color="black" size="x-small" class="mt-3"
                 @click="showInfoDialog($t('form.steps.transport.tooltip_courier_dpd'))"/>
        </v-col>
        <v-col>
          <app-animated-button variant="transport_self_deliver"
                               :selected="claim_form.transport_in === 'transport_self_deliver'"
                               @click="updateClaimForm({ key: 'transport_in', value: 'transport_self_deliver' });
                                       updateClaimForm({ key: 'transport_out', value: 'transport_self_deliver' });"/>
          <v-btn icon="mdi-help" color="black" size="x-small" class="mt-3"
                 @click="showInfoDialog($t('form.steps.transport.tooltip_self_delivery'))"/>
        </v-col>
      </v-row>
    </v-container>
    <v-row v-if="claim_form.transport_in === 'transport_dpd'" dense no-gutters class="mt-3 px-6">
      <v-col>
        <v-card elevation="1" color="options_menu" class="px-5">
          <v-card-text>
            <p class="subtitle-sm">{{ $t('form.steps.transport.select_transport_date_day') }}</p>
            <div id="days_buttons" class="ma-3 mt-0">
              <v-btn color="black" size="small" class="mt-3 ml-2"
                     v-for="(item, i) in days" :key="i"
                     :variant="claim_form.transport_day === item ? 'contained' : 'outlined'"
                     @click="updateClaimForm({ key: 'transport_day', value: item });updateSelectableHours(item)">
                {{item}}
              </v-btn>
            </div>
            <v-row>
              <v-col class="v-col-auto justify-end align-center d-flex">
                <p class="subtitle-sm">{{ $t('form.steps.transport.select_transport_date_hours') }}</p>
              </v-col>
              <v-col class="align-center d-flex">
            <v-select
                v-model="claim_form.transport_hours"
                variant="outlined" color="primary" density="compact"
                hide-details="auto"
                :items="selectableHours" item-value="id" item-title="value" class=""
            ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="claim_form.transport_in === 'transport_dpd'" :class="['pa-3', 'px-8']">
      <v-col :class="[...classes.print_label_check, claim_form.client_print_label ? 'print-label-check-checked' : '','darias-font-size']"
             @click="updateClaimForm({ key: 'client_print_label', value: !claim_form.client_print_label })">
        <v-icon :icon="claim_form.client_print_label ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`"/>
        {{ $t('form.steps.transport.client_print_label') }}
      </v-col>
    </v-row>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {useDisplay} from "vuetify";
import {computed} from "vue";

export default {
  name: "EcoTransportIn",
  setup() {
    const {name} = useDisplay()
    // eslint-disable-next-line vue/return-in-computed-property
    const classes = computed(() => {
      // name is reactive and
      // must use .value
      const isModal = window.self !== window.top;
      if(isModal) {
        return {
          density: 'compact',
          buttons_container: ['px-15', 'py-0'],
          print_label_check: ['print-label-check', 'print-label-check-align-center', 'mt-3']
        }
      } else {
        switch (name.value) {
          case 'xs':
            return {
              print_label_check: ['print-label-check', 'print-label-check-align-center', 'mt-3'],
            }
          case 'sm':
            return {
              print_label_check: ['print-label-check', 'print-label-check-align-center', 'pa-2', 'mt-3'],
            }
          default:
            return {
              print_label_check: ['print-label-check', 'print-label-check-align-justify', 'mt-3'],
            }
        }
      }
    })
    return {classes, screen_size: name.value,}
  },
  data() {
    return {
      show_courier_tooltip: false,
      show_self_delivery_tooltip: false,
      selectableHours:[],
    }
  },
  computed: {
    ...mapGetters('ClaimForm', {claim_form: 'claim_form'}),
    ...mapGetters('Courier', {availability: 'availability', courier: 'courier', days: 'days'}),
  },
  mounted() {
    if(this.claim_form.transport_day){
      this.updateSelectableHours(this.claim_form.transport_day);
    }
  },
  methods: {
    ...mapActions('ClaimForm', {updateClaimForm: 'updateClaimForm', syncValue: 'syncValue'}),
    ...mapActions('AppInfoDialog', {showInfoDialog: 'showInfoDialog'}),
    ...mapActions('Courier', {getCourierData: 'getCourierAvailability'}),
    loadCourierData: function (option_name) {
      // console.log('Loading couriers for '+option_name+' / '+ this.claim_form.sender.postal_code);
      this.getCourierData({
            caller: this,
            courier: {
              courier: option_name,
              postalCode: this.claim_form.sender.postal_code
            }
      })
    },
    updateSelectableHours: function (day)
    {
      let sh = []
      this.availability.forEach(function (item) {
        if(item.date === day){
          sh = item.hours;
          return item.hours
        }
      });

      if(!sh.find(({ id }) => id === this.claim_form.transport_hours)){
        this.updateClaimForm({ key: 'transport_hours', value: "" })
      }

      this.selectableHours = sh;
      return sh;
    }
  },
}
</script>

<style scoped>

.transport-menu {
  padding: 20px;
  background-color: #EBEDED;
  border-radius: 15px;
}

.center-txt {
  text-align: center;
}

.darias-font-size {
  font-size: 15px;
}

</style>