import * as Api from "./Api";

export default {
    index(query) {
        let queryObj =
        {
            postal_code: query.postalCode,
            courier: query.courier
        }

        return Api.client.post(`/pickup`,queryObj);
    },
    translateOption(option_name)
    {
        switch (option_name){
            case "transport_dpd":
                return "DPD";
            case "transport_dhl":
                return "DHL24"
            default:
                throw "Unknown option name: '"+option_name+"'";
        }
    }
};
